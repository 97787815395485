import React from "react";
// import Mail from "../../components/Contact/Mail/Mail";
import Payment from "../../components/Contact/Payment/Payment";

function Contact() {
  return (
    <>
      {/* <Mail /> */}
      <Payment />
    </>
  );
}

export default Contact;
