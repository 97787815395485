import React from "react";
import "./Hero.css";
import background from "../../../images/rhero.jpg";
import { motion, easeOut } from "framer-motion";

function Hero() {
  return (
    <div className="landlords_hero">
      <div
        className="landlords_hero_container"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div></div>
        <motion.h1
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.8, ease: easeOut }}
          viewport={{ once: true }}
        >
          Landlords
        </motion.h1>
        <motion.span
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.9, ease: easeOut }}
          viewport={{ once: true }}
        ></motion.span>
        <motion.p
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, ease: easeOut }}
          viewport={{ once: true }}
        >
          Landlord are pivotal part of an economy, creating and providing living
          accomodation to the general public. As a landlord, you are require to
          be compliant and follow the bechmark set up by the governement to
          provide safe living standard. We are here to help you with all your
          property needs from selling the property, guide you through the rules
          and legislation to keep youccomodate and give you all the nessasary
          tools to keep your property compliant and safe for the tenant.
        </motion.p>
      </div>
    </div>
  );
}

export default Hero;
