import React, { useState } from "react";

import toast from "../../utils/toast";

import "./StripePay.css";
import { loadStripe } from "@stripe/stripe-js";

const currencies = [
  {
    value: "usd",
    name: "USD",
  },
  {
    value: "eur",
    name: "EUR",
  },
  {
    value: "gbp",
    name: "GBP",
  },
];

function StripePay() {
  const [userInputs, setUserInputs] = useState({
    price: null,
    currency: "usd",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const makePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!(userInputs.price && userInputs.price >= 10)) {
        setError(true);
      } else {
        setError(false);

        const product = {
          name: "Colett Payment",
          price: userInputs.price,
          productBy: "Colett",
        };
        const stripe = await loadStripe(process.env.REACT_APP_STRIP_PUBLIC_KEY);

        const body = {
          product,
          currency: userInputs.currency,
        };

        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/create-checkout-session`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(body),
          }
        );

        const session = await response.json();
        stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <div className="stripe">
      <div className="container">
        <form onSubmit={makePayment}>
          <h1>Make Payment</h1>
          <div className="row">
            <div className="inputs">
              <label htmlFor="amount">Amount</label>
              <input
                value={userInputs.price}
                onChange={(e) => {
                  setUserInputs({ ...userInputs, price: e.target.value });
                }}
                type="number"
                required
                placeholder="Enter the amount."
                name="amount"
              />
              {error && <p className="error">Minimum amount is 10.</p>}
            </div>

            <div className="inputs">
              <label htmlFor="currency">Currency</label>
              <select
                name="currency"
                value={userInputs.currency}
                onChange={(e) => {
                  setUserInputs({ ...userInputs, currency: e.target.value });
                }}
              >
                {currencies.map((currency, i) => {
                  return (
                    <option value={currency.value} key={i}>
                      {currency.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={`row loader ${loading ? "load" : ""}`}>
            <span></span>
          </div>
          <button disabled={loading} className="pay-btn" onClick={makePayment}>
            {loading ? "Wait" : "Pay"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default StripePay;
