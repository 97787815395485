import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Landlords from './pages/Landlords/Landlords';
import Contact from './pages/Contact/Contact';
import Invest from './pages/Invest/Invest';
import Sourcing from './pages/Sourcing/Sourcing';
// import Resources from './pages/Resources/Resources';
import ScrollToTop from './components/ScrollToTop';
import { FaWhatsapp } from "react-icons/fa";

import 'react-toastify/dist/ReactToastify.css';
import StripePay from './components/StripePay/StripePay';
import Failed from './components/Payment/Failed';
import Success from './components/Payment/Success';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <div className='whatsapp'>
        <a href='https://wa.me/+447401802003' target='black'>
          <FaWhatsapp />
        </a>
      </div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/landlords" element={<Landlords />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/invest-with-us" element={<Invest />} />
        <Route exact path="/sourcing" element={<Sourcing />} />
        {/* <Route exact path="/resources" element={<Resources />} />
        */}
        <Route exact path="/payment" element={<StripePay />} />
        <Route exact path="/payment/failed" element={<Failed />} />
        <Route exact path="/payment/success" element={<Success />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
