import React, { useEffect, useState } from "react";
import "./Navbar.css";
import logo from "../../images/logo1.JPG";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineBars, AiFillCloseCircle } from "react-icons/ai";
import { easeOut, motion } from "framer-motion";

function Navbar() {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [changeColor, setChangeColor] = useState(false);

  const changeBackground = () => {
    if (window.scrollY > 10) {
      setChangeColor(true);
    } else {
      setChangeColor(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <div className={`navbar ${changeColor ? "change" : ""}`}>
      <div className={`navbar_container`}>
        <div className="logo">
          <Link to="/">
            <motion.img
              whileInView={{ y: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.2, ease: easeOut }}
              viewport={{ once: true }}
              src={logo}
              alt=""
            />
          </Link>
        </div>
        <ul
          className={openMenu ? "open" : ""}
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <motion.li
            whileInView={{ y: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.3, ease: easeOut }}
            viewport={{ once: true }}
            className={location.pathname === "/" ? "active" : ""}
          >
            <Link to="/">Home</Link>
          </motion.li>
          <motion.li
            whileInView={{ y: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.4, ease: easeOut }}
            viewport={{ once: true }}
            className={location.pathname === "/sourcing" ? "active" : ""}
          >
            <Link to="/sourcing">Sourcing</Link>
          </motion.li>
          <motion.li
            whileInView={{ y: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.5, ease: easeOut }}
            viewport={{ once: true }}
            className={location.pathname === "/invest-with-us" ? "active" : ""}
          >
            <Link to="/invest-with-us">Invest With Us</Link>
          </motion.li>
          <motion.li
            whileInView={{ y: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.6, ease: easeOut }}
            viewport={{ once: true }}
            className={location.pathname === "/landlords" ? "active" : ""}
          >
            <Link to="/landlords">Landlords</Link>
          </motion.li>
          {/* <motion.li
            whileInView={{ y: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className={location.pathname === "/resources" ? "active" : ""}
          >
            <Link to="/resources">Resources</Link>
          </motion.li> */}
          <motion.li
            whileInView={{ y: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.8, ease: easeOut }}
            viewport={{ once: true }}
          >
            <Link
              to="/contact-us"
              className={`button mobile ${
                location.pathname === "/contact-us" ? "active" : ""
              }`}
            >
              Contact Us
            </Link>
          </motion.li>
        </ul>
        <div
          whileInView={{ y: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.8, ease: easeOut }}
          viewport={{ once: true }}
        >
          <Link
            to="/contact-us"
            className={`button desktop ${
              location.pathname === "/contact-us" ? "active" : ""
            }`}
          >
            Contact Us
          </Link>
        </div>
        <div
          className={`mobile bars ${openMenu ? "rotate" : ""}`}
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          {openMenu ? <AiFillCloseCircle /> : <AiOutlineBars />}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
