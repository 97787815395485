import React, { useEffect } from "react";
import Hero from "../../components/Home/Hero/Hero";
import About from "../../components/Home/About/About";
import Service from "../../components/Home/Service/Service";
// import Source from "../../components/Home/Source/Source";
import Resource from "../../components/Home/Resource/Resource";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Details from "../../components/Home/Details/Details";

function Home() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search === "?status=success") {
      navigate("/payment/success");
    } else if (location.search === "?status=failed") {
      navigate("/payment/failed");
    }
  }, [location.search]);

  return (
    <>
      <Hero />
      <About />
      <Service />
      {/* <Source /> */}
      <Resource />
      {/* <Details /> */}
    </>
  );
}

export default Home;
