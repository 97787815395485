import "./Hero.css";
import background from "../../../images/ihero.jpg";
import { motion, easeOut } from "framer-motion";

function Hero() {
  return (
    <div className="invest_hero">
      <div
        className="invest_hero_container"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div></div>
        <motion.h1
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.8, ease: easeOut }}
          viewport={{ once: true }}
        >
          Invest with us
        </motion.h1>
        <motion.span
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.9, ease: easeOut }}
          viewport={{ once: true }}
        ></motion.span>
        <motion.p
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, ease: easeOut }}
          viewport={{ once: true }}
        >
          Why property and why us? <br /> <br />
          Firstly, real estate has historically demonstrated a tendency to
          appreciate over time, providing a potential hedge against economic
          downturns.
          <br /> <br /> Secondly, property values often show resilience, and
          well-located properties tend to retain their intrinsic worth, also
          providing steady cash flow, reducing dependency on market fluctuations
          compare to other volitile investment like stocks. <br /> <br />
          Lastly, proper risk management strategies, such as thorough research,
          diversification, and prudent financing, can further enhance the safety
          and stability of property investments. With our expert team, we will
          guide you through the journey of property investment with us.
        </motion.p>
      </div>
    </div>
  );
}

export default Hero;
