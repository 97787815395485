import React from "react";
import "./Provide.css";
import { easeOut, motion } from "framer-motion";

import image1 from "../../../images/mansion1.avif";
import image2 from "../../../images/mansion2.webp";
import image3 from "../../../images/mansion3.jpg";
import image4 from "../../../images/mansion4.jpg";
import { Link } from "react-router-dom";

function Provide() {
  return (
    <div className="provide">
      <div className="provide_container">
        <motion.h1
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.4, ease: easeOut }}
          viewport={{ once: true }}
        >
          What We Provide
          <span></span>
        </motion.h1>

        <div className="item">
          <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.4, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${image1})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, ease: easeOut }}
              viewport={{ once: true }}
            >
              No Estate Agent Fees
            </motion.h1>
            <motion.p
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.6, ease: easeOut }}
              viewport={{ once: true }}
            >
              With us you can sell for free. Yes, that's correct. As a buyer's
              agent, our service fees are sourced from the buyer. Meaning if
              you're an existing landlord, owner of a portfolio, or have an
              empty property you can sell for free with us. <br /> <br />
              <Link to={"/contact-us"}>Learn More</Link>
            </motion.p>
          </div>
        </div>

        <div className="item reverse">
          <motion.div
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.4, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${image2})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, ease: easeOut }}
              viewport={{ once: true }}
            >
              Speed
            </motion.h1>
            <motion.p
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.6, ease: easeOut }}
              viewport={{ once: true }}
            >
              We vett our Buyers regriously, having professional solicitors
              ready to go, and funding organised. This means once you have an
              offer agreed, legals can proceed instantly with a timely
              completion. <br /> <br />
              <Link to={"/contact-us"}>Learn More</Link>
            </motion.p>
          </div>
        </div>

        <div className="item ">
          <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.4, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${image3})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, ease: easeOut }}
              viewport={{ once: true }}
            >
              Experience
            </motion.h1>
            <motion.p
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.6, ease: easeOut }}
              viewport={{ once: true }}
            >
              Our buyers are existing investors. This means they already know
              the process of buying property in the UK. They will want to move
              as quickly as possible. <br /> <br />
              <Link to={"/contact-us"}>Learn More</Link>
            </motion.p>
          </div>
        </div>

        <div className="item reverse">
          <motion.div
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.4, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${image4})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.5, ease: easeOut }}
              viewport={{ once: true }}
            >
              View once and ready to go
            </motion.h1>
            <motion.p
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.6, ease: easeOut }}
              viewport={{ once: true }}
            >
              Unlike your regular high Street Estate agent, we value
              convineience and ease. Being digital is the quickly and hassle
              free way to view the property. We view the property, Record a
              video, Highlight all the worrysome parts and send this to our
              world wide buyer. With the trust we built, this will be enough for
              any investor to move quickly. <br /> <br />
              <Link to={"/contact-us"}>Learn More</Link>
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Provide;
