import React from "react";
import "./Hero.css";
import background from "../../../images/03.jpg";
import { easeOut, motion } from "framer-motion";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="hero">
      <div className="hero_container">
        <div className="left">
          <motion.h1
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            Sourcing
          </motion.h1>
          <motion.p
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            We source properties acting as a buyer's agent for our list of
            clients, working closly with them on finding their perfect
            investment homes.
          </motion.p>
          <Link to={"/sourcing"}>Learn More</Link>
        </div>
        <div className="right">
          <motion.h1
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            Invest With Us
          </motion.h1>
          <motion.p
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            For our clients who wants to make fantastic return in their capital
            without the hassle of taking ownership of the property.
          </motion.p>
          <Link to={"/invest-with-us"}>Learn More</Link>
        </div>
        <div
          className="right down"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="back"></div>
          <motion.h1
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            Landlords
          </motion.h1>
          <motion.p
            whileInView={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            Landlord are pivotal part of an economy, creating and providing
            living accomodation to the general public.
          </motion.p>
          <Link to={"/landlords"}>Learn More</Link>
        </div>
      </div>
    </div>
  );
}

export default Hero;
