import React from "react";
import Hero from "../../components/Invest/Hero/Hero";
import Options from "../../components/Invest/Options/Options";

function Invest() {
  return (
    <>
      <Hero />
      <Options />
    </>
  );
}

export default Invest;
