import React from "react";
import Main from "../../components/Landlords/Main/Main";
import Hero from "../../components/Landlords/Hero/Hero";
import Provide from "../../components/Landlords/Provide/Provide";

function Landlords() {
  return (
    <>
      <Hero />
      <Main />
      <Provide />
    </>
  );
}

export default Landlords;
