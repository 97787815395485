import React from "react";

function Success() {
  return (
    <div className="pay success">
      <div className="container">
        <h1>Payment Success</h1>
        <p>Congratulations. Your payment has been succeded.</p>
      </div>
    </div>
  );
}

export default Success;
