import React from "react";
import Hero from "../../components/Sourcing/Hero/Hero";
import Highlight from "../../components/Sourcing/Highlight/Highlight";

function Sourcing() {
  return (
    <>
      <Hero />
      <Highlight />
    </>
  );
}

export default Sourcing;
