import "./Hero.css";
import background from "../../../images/hero2.jpg";
import { easeOut, motion } from "framer-motion";

function Hero() {
  return (
    <div className="source_hero">
      <div
        className="source_hero_container"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div></div>
        <motion.h1
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.8, ease: easeOut }}
          viewport={{ once: true }}
        >
          Property Sourcing
        </motion.h1>
        <motion.span
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.9, ease: easeOut }}
          viewport={{ once: true }}
        ></motion.span>
        <motion.p
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 1, ease: easeOut }}
          viewport={{ once: true }}
        >
          We source properties acting as a buyer's agent for our list of
          clients, working closly with them on finding their perfect investment
          homes.
        </motion.p>
      </div>
    </div>
  );
}

export default Hero;
