import React from "react";
import "./Payment.css";
import { easeOut, motion } from "framer-motion";

// import image1 from "../../../images/mansion1.avif";
import { Link } from "react-router-dom";

const Payment = () => {
  return (
    // <div className="payment" style={{ backgroundImage: `url(${image1})` }}>
    <div className="payment">
      <div className="blur"></div>
      <div className="payment_container">
        <motion.div
          className="item"
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.7, ease: easeOut }}
          viewport={{ once: true }}
        >
          <div>
            <h1>
              About us <span></span>
            </h1>
            <p>
              Worked with other sourcing agent and the deal doesn't make sence
              or they have taken the payment and left you to deal with
              everything. Unfortunately, this is very normal. <br /> <br />
              This is why we are different, we at Colett ltd, hand hold you from
              1 to z of the process, taking care of all the issues that may
              arise throughout the journey. May you be a seasoned investor or
              buying your first investment property, we will be there for your
              every needs. <br /> <br />
              We can introduce you to our solicitors, mortgage brokers,
              insurers, surveyors, and managing agents. All clients get access
              to our experienced team.
            </p>
          </div>
        </motion.div>

        <motion.div
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.7, ease: easeOut }}
          viewport={{ once: true }}
          className="item reverse"
        >
          <div>
            <h1>
              Join Our Plan <span></span>
            </h1>
            <p>
              {" "}
              If you would like join our Basic Plan or sign up to the Inner
              circle and want to know about services. Register yourself below or
              book your 15mins chat in our callender and our dedicated team will
              be at your service. <br /> <br />
              If you like to speak to our team urgently then send us a whatsapp
              message through our online service.
            </p>
          </div>
        </motion.div>

        <motion.div
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.7, ease: easeOut }}
          viewport={{ once: true }}
          className="item criteria"
        >
          <div>
            <h1>
              Our Criteria Form <span></span>
            </h1>
            <p>
              Click the button below to complete our quick registration
              questionnaire. Someone from the team will then be in touch
              directly to organise your free consultation.
            </p>
            <a
              className="button"
              href="https://form.jotform.com/233242619539056"
            >
              Click Here
            </a>
            {/* <p>Or Scan the QR</p>
            <a href="https://form.jotform.com/233242619539056" rel="no-follow">
              <img
                src="https://www.jotform.com/uploads/Ramdam_Yugal/form_files/233242619539056_1700577188_qrcode_muse.png"
                style={{ width: "100%", maxWidth: "200px" }}
                alt="QR Code for Jotform form"
              />
            </a> */}
            <h1>
              Book a call with us <span></span>
            </h1>
            <a
              className="button"
              href="https://form.jotform.com/233244546753055"
            >
              Click Here
            </a>
            {/* <p>Or Scan the QR</p>
            <a href="https://form.jotform.com/233244546753055" rel="no-follow">
              <img
                src="https://www.jotform.com/uploads/Ramdam_Yugal/form_files/233244546753055_1700582834_qrcode_muse.png"
                style={{ width: "100%", maxWidth: "200px" }}
                alt="QR Code for Jotform form"
              />
            </a> */}
          </div>
        </motion.div>

        <motion.div
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.7, ease: easeOut }}
          viewport={{ once: true }}
          className="item reverse"
        >
          <div>
            <h1>
              Make Payments <span></span>
            </h1>
            <p>CLICK BELOW TO MAKE PAYMENTS.</p>
            <>
              <Link className="pay-btn" to={"/payment"}>
                Pay
              </Link>
            </>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Payment;
