import "./Options.css";
import { Link } from "react-router-dom";
import { easeOut, motion } from "framer-motion";
import background1 from "../../../images/i1.jpg";
import background2 from "../../../images/i2.jpg";

function Options() {
  return (
    <div className="options">
      <div className="options_container">
        <div className="item reverse">
          <motion.div
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${background1})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.8, ease: easeOut }}
              viewport={{ once: true }}
            >
              Fixed Return Plan
            </motion.h1>
            <span></span>
            <motion.p
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.9, ease: easeOut }}
              viewport={{ once: true }}
            >
              For our clients who wants to make fantastic return in their
              capital without the hassle of taking management and renovation
              responsiblity of the property. As you know brick and Mortar are
              long lasting tangible asset that can give you value for your
              money, when invested correctly it will be a sustainable long term
              return on your capital. The security of a fixed return can be
              attractive for risk-averse investors seeking a reliable source of
              income. <br /> <br />
              We provide a fix return of 8-10% on your capital. We provide
              detailed report and updates on our current affairs and capitalise
              utilization is done for the project. To invest, set up a 15min
              phone call to find our more. <br /> <br />
              Property is very powerful and you can see the long term
              compounding effect of investing your capital. If you invest 10,000
              every year with us, below you can see the result of compouding
              taking affect.
            </motion.p>
            <motion.table
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1, ease: easeOut }}
              viewport={{ once: true }}
            >
              <tr>
                <th>Amount</th>
                <th>&#163; 10,000</th>
              </tr>
              <tr>
                <th>Interest</th>
                <th>10%</th>
              </tr>
            </motion.table>

            <motion.table
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1.1, ease: easeOut }}
              viewport={{ once: true }}
            >
              <tr>
                <th>Years</th>
                <th>Capital</th>
                <th>Interest</th>
                <th>Growth</th>
              </tr>
              <tr>
                <td>Y1</td>
                <td>&#163; 10,000</td>
                <td>&#163; 1,000</td>
                <td>&#163; 11,000</td>
              </tr>
              <tr>
                <td>Y2</td>
                <td>&#163; 11,000</td>
                <td>&#163; 1,100</td>
                <td>&#163; 12,100</td>
              </tr>
              <tr>
                <td>Y3</td>
                <td>&#163; 12,100</td>
                <td>&#163; 1,210</td>
                <td>&#163; 13,310</td>
              </tr>
              <tr>
                <td>Y5</td>
                <td>&#163; 14,641</td>
                <td>&#163; 1,464</td>
                <td>&#163; 16,105</td>
              </tr>
              <tr>
                <td>Y10</td>
                <td>&#163; 23,579</td>
                <td>&#163; 2,358</td>
                <td>&#163; 25,937</td>
              </tr>
              <tr>
                <td>Y20</td>
                <td>&#163; 61,159</td>
                <td>&#163; 6,116</td>
                <td>&#163; 67,275</td>
              </tr>
              <tr>
                <td>Y30</td>
                <td>&#163; 158,631</td>
                <td>&#163; 15,863</td>
                <td>&#163; 174,494</td>
              </tr>
            </motion.table>
            <motion.div
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1.2, ease: easeOut }}
              viewport={{ once: true }}
            >
              <Link to="/contact-us">Learn More</Link>
            </motion.div>
          </div>
        </div>
        <div className="item">
          <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className="image"
            style={{ backgroundImage: `url(${background2})` }}
          ></motion.div>
          <div className="content">
            <motion.h1
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.8, ease: easeOut }}
              viewport={{ once: true }}
            >
              Lend and Learn - JV Opportunities
            </motion.h1>
            <span></span>
            <motion.p
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.9, ease: easeOut }}
              viewport={{ once: true }}
            >
              If you are an experienced investor or high net worth individual
              and want to invest your money and also be part of the project then
              we can help you with that. <br /> <br />
              You can stay hands off while allowing your capital work hard for
              you. We are here to provide our expertise on making the deal work
              from start to finish while you provide the capital. <br /> <br />
              You can also be part of the journey and learn while your money
              does the work for you. <br /> <br />
              If you would like to JV with us or go through our 'Lend and learn'
              route then book a 15mins section on our callender.
            </motion.p>
            <motion.div
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 1, ease: easeOut }}
              viewport={{ once: true }}
            >
              <Link to="https://form.jotform.com/233244546753055">
                Learn More
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Options;
