import React, { useEffect, useState } from "react";
import "./Resource.css";

import background1 from "../../../images/resource.jpg";
import background2 from "../../../images/03.jpg";

import image from "../../../images/boy1.jpg";
import image2 from "../../../images/boy2.jpg";
import image3 from "../../../images/girl.jpg";
import { FaQuoteRight } from "react-icons/fa";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { easeOut, motion } from "framer-motion";

const data = [
  {
    name: "Marc Atticus",
    tag: "",
    comment:
      "Yugal was very professional and hand holded us all the way through, from sourcing the unit to renting it out.",
    image: image,
  },
  {
    name: "Ultan Hanni",
    tag: "",
    comment:
      "They are reliable, honest and operate with integrity. I recommend them to anyone who is looking for investment properties. They do their duedeligence as per the books and lay it out everything to you. They take time to answer all the questions and sections elaboratly and provide me with options and what it will lead to.",
    image: image2,
  },
  {
    name: "Arie Iris",
    tag: "",
    comment:
      "I was refferd to Colett after attending seminar, they have found me a good HMO refurb unit for us to renovate and enjoy the cashflow. Really good, straight to the point.",
    image: image3,
  },
];

function Resource() {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [start, setStart] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setStart(true);

      setTimeout(() => {
        setCurrentIndex((index) => (index !== data.length - 1 ? ++index : 0));
        setStart(false);
      }, 500);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="resource">
      <div className="resource_container">
        <section className="upper">
          <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
            className={`left ${start ? "move" : ""}`}
          >
            <div className="top">
              <p>{data[currentIndex]?.comment}</p>
              <span>
                <FaQuoteRight />
              </span>
            </div>
            <div className="bottom">
              <img src={data[currentIndex]?.image} alt="" />
              <div>
                <h2>{data[currentIndex]?.name}</h2>
                <p>{data[currentIndex]?.tag}</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.8, ease: easeOut }}
            viewport={{ once: true }}
            className="right"
            style={{ backgroundImage: `url(${background1})` }}
          >
            <div className="top"></div>
            <Link to={"/contact-us"}>
              Learn More <BsFillArrowRightCircleFill />
            </Link>
          </motion.div>
        </section>
        <motion.section
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.9, ease: easeOut }}
          viewport={{ once: true }}
          className="lower"
          style={{ backgroundImage: `url(${background2})` }}
        >
          <article className="background"></article>
          <h1>
            Your Journey to Financial Freedom Starts Here
            <br />
            <a href="https://form.jotform.com/233244546753055">
              Book Your Free Call
            </a>
          </h1>
          <div>
            <Link to={"/contact-us"}>Get Started</Link>
          </div>
        </motion.section>
      </div>
    </div>
  );
}

export default Resource;
