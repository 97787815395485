import React from "react";

function Failed() {
  return (
    <div className="pay failed">
      <div className="container">
        <h1>Payment Failed</h1>
        <p>Sorry. Your payment has been failed.</p>
      </div>
    </div>
  );
}

export default Failed;
