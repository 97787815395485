import React from "react";
import "./Main.css";
import background from "../../../images/landlord.jpg";
import { FaHome, FaShippingFast, FaMoneyBillAlt } from "react-icons/fa";
import { easeOut, motion } from "framer-motion";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="landlords">
      <div className="landlords_container">
        <div className="right">
          <motion.h1
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7, ease: easeOut }}
            viewport={{ once: true }}
          >
            We cater to Landlord and their needs.
          </motion.h1>
          <motion.span
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.8, ease: easeOut }}
            viewport={{ once: true }}
            className="line"
          ></motion.span>
          <div className="wrapper">
            <div className="list">
              <div className="icon">
                <FaHome />
              </div>
              <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.7, ease: easeOut }}
                viewport={{ once: true }}
                className="content"
              >
                <h2>Property for Sale</h2>
                <p>
                  You would like to retire, release some equity or sell the
                  unit, using our professional team, we can help you sell the
                  property in the open market or to one of our client from the
                  data base.
                  <br />
                  <Link to={"/contact-us"}>Learn More</Link>
                </p>
              </motion.div>
            </div>
            <div className="list">
              <div className="icon">
                <FaShippingFast />
              </div>
              <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8, ease: easeOut }}
                viewport={{ once: true }}
                className="content"
              >
                <h2>Sell my property fast - Auction</h2>
                <p>
                  You have been in the market for many months where the agent
                  promise you this and that. The agent will give you numerous
                  excuses and make you feel guilty about the price. If you are
                  struglling to sell your property, we can help and hand hold
                  you through selling the property through auction route. This
                  will be a no fee cost to yourself and we will handhold you
                  through the process.
                  <br />
                  <Link to={"/contact-us"}>Learn More</Link>
                </p>
              </motion.div>
            </div>
            <div className="list">
              <div className="icon">
                {" "}
                <FaMoneyBillAlt />
              </div>
              <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.9, ease: easeOut }}
                viewport={{ once: true }}
                className="content"
              >
                <h2>Manage my property</h2>
                <p>
                  Congratulations! Going back and forth through the conveyacing
                  and calling each party, finally you have landed yourself the
                  property. Struggling to let or want to go through our
                  garaunteed rent and start cash flowing then we can guide you
                  and take the property off your hands providing regular income
                  and maintenance.
                  <br />
                  <Link to={"/contact-us"}>Learn More</Link>
                </p>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="left">
          {/* <motion.p
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1, ease: easeOut }}
            viewport={{ once: true }}
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero, vel
            optio nemo autem veniam quos velit pariatur? Dolorem, quod excepturi
            temporibus corrupti quibusdam accusamus quam ipsa quasi eveniet.
            Officia, delectus.
          </motion.p> */}
          <motion.img
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1.1, ease: easeOut }}
            viewport={{ once: true }}
            src={background}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Main;
